<template>
	<div class="slider">
		<hooper style="height: auto" :transition="500" :wheelControl="false" :trimWhiteSpace="true" >
			<slide>
				<div class="image">
					<img src="../assets/slide-codex.png" alt="grid of collectable vairus">
				</div>
			</slide>
			<slide>
				<div class="image">
					<img src="../assets/slide-network.png" alt="network of your websites">
				</div>
			</slide>
			<slide>
				<div class="image">
					<img src="../assets/slide-details.png" alt="details of a vairus">
				</div>
			</slide>
			
			<hooper-navigation slot="hooper-addons"></hooper-navigation>
		</hooper >
	</div>
</template>

<script>
	import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper';
	import 'hooper/dist/hooper.css';
	
	export default {
		name: 'Slider',
		data: function () {
			return{
				slides : ['codex', 'network', 'details'],
			}
		},
		components: {
			Hooper, 
			Slide,
			HooperNavigation
		}
	}
</script>

<style scoped lang="scss">
	.slider{
		.image{
			position: relative;
			width: 100%;
			overflow: hidden;
			
			img{
				width: 100%;
				height: 100%;
				max-height: 90vh;
				object-position: center;
				object-fit: cover;
				pointer-events: none;
			}
			
			@media screen and (max-width: 800px) {
				img{
					width: 120%;
					margin-left: -10%;
				}
			}
			
			@media screen and (max-width: 600px) {
				img{
					width: 140%;
					margin-left: -20%;
				}
			}
		}
	}
</style>

<style lang="scss">
	.hooper-navigation {
		button{
			overflow: hidden;
			
			svg {
				width: 60px;
				height: 60px;
				
				path:last-child{
					fill: #B3EDFF;
				}
			}
			
			@media screen and (max-width: 600px) {
				&.hooper-prev svg{
					margin-left: -25px;
				}
				&.hooper-next svg{
					margin-right: -25px;
				}
			}
		}
	}
</style>
