<template>
	<footer>
		<div class="main-column row-padding flex space v-center">
			<div class="site-branding hustle col-2" data-text="vairus">
				<a href="/">vairus</a>
			</div>
			
			<div class="navigation col-7">
				<a href="?privacy" v-if="page != 'article'">{{ $t('message.privacy.title') }}</a>
			</div>
			
			<div class="links col-3">
				<a href="https://discord.gg/qea9gz5RkQ">
					<svg style="width:24px;height:24px" viewBox="0 0 24 24">
						<path fill="#B3EDFF" d="M22,24L16.75,19L17.38,21H4.5A2.5,2.5 0 0,1 2,18.5V3.5A2.5,2.5 0 0,1 4.5,1H19.5A2.5,2.5 0 0,1 22,3.5V24M12,6.8C9.32,6.8 7.44,7.95 7.44,7.95C8.47,7.03 10.27,6.5 10.27,6.5L10.1,6.33C8.41,6.36 6.88,7.53 6.88,7.53C5.16,11.12 5.27,14.22 5.27,14.22C6.67,16.03 8.75,15.9 8.75,15.9L9.46,15C8.21,14.73 7.42,13.62 7.42,13.62C7.42,13.62 9.3,14.9 12,14.9C14.7,14.9 16.58,13.62 16.58,13.62C16.58,13.62 15.79,14.73 14.54,15L15.25,15.9C15.25,15.9 17.33,16.03 18.73,14.22C18.73,14.22 18.84,11.12 17.12,7.53C17.12,7.53 15.59,6.36 13.9,6.33L13.73,6.5C13.73,6.5 15.53,7.03 16.56,7.95C16.56,7.95 14.68,6.8 12,6.8M9.93,10.59C10.58,10.59 11.11,11.16 11.1,11.86C11.1,12.55 10.58,13.13 9.93,13.13C9.29,13.13 8.77,12.55 8.77,11.86C8.77,11.16 9.28,10.59 9.93,10.59M14.1,10.59C14.75,10.59 15.27,11.16 15.27,11.86C15.27,12.55 14.75,13.13 14.1,13.13C13.46,13.13 12.94,12.55 12.94,11.86C12.94,11.16 13.45,10.59 14.1,10.59Z" />
					</svg>
				</a>
				<a href="https://www.patreon.com/vairus">
					<svg width="24px" height="24px" viewBox="0 0 24 24">
						<path fill="#B3EDFF" d="M14.82 2.41C18.78 2.41 22 5.65 22 9.62C22 13.58 18.78 16.8 14.82 16.8C10.85 16.8 7.61 13.58 7.61 9.62C7.61 5.65 10.85 2.41 14.82 2.41M2 21.6H5.5V2.41H2V21.6Z" />
					</svg>
				</a>
				<a href="mailto:info@playvairus.com">
					<svg width="24px" height="24px" viewBox="0 0 24 24">
						<path fill="#B3EDFF" d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/>
					</svg>
				</a>
			</div>
		</div>
	</footer>
</template>

<script>
	export default {
		name: 'Footer',
		props : ['page']
	}
</script>

<style scoped lang="scss">
	footer{
		position: relative;
		a{
			color: inherit;
			text-decoration: none;
			margin-left: 10px;
		}
		
		.navigation{
			margin-top: -10px;
		}
		
		.links{
			display: flex;
			justify-content: flex-end;
		}
		
		@media screen and (max-width: 650px) {
			margin-bottom: 50px;
			
			.navigation{
				position: absolute;
				top: 100px;
			}
		}
	}
</style>
