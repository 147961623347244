<template>
	<div id="app">
		<Header @download="goToDownload" :page="page" />
	
		<div class="content" v-if="page == 'home'">
			<trailer/>
			
			<explanation />
			
			<infos />
			
			<Slider />
			
			<download ref="download"/>
		</div>
	
		<div class="content privacy-page" v-if="page == 'privacy'">

			<div class="main-column medium-font">
				<h2>Privacy f.a.q.</h2>
				
				<div class="faq">
					<div class="question medium-large-font" v-html="$t('message.privacy.q1.question')"></div>
					<div class="answer" v-html="$t('message.privacy.q1.answer')"></div>
				</div>
				
				<div class="faq">
					<div class="question medium-large-font" v-html="$t('message.privacy.q2.question')"></div>
					<div class="answer" v-html="$t('message.privacy.q2.answer')"></div>
				</div>
				
				<div class="faq">
					<div class="question medium-large-font" v-html="$t('message.privacy.q3.question')"></div>
					<div class="answer" v-html="$t('message.privacy.q3.answer')"></div>
				</div>
				
				<div class="faq">
					<div class="question medium-large-font" v-html="$t('message.privacy.q4.question')"></div>
					<div class="answer" v-html="$t('message.privacy.q4.answer')"></div>
				</div>
				
				<div class="faq">
					<div class="question medium-large-font" v-html="$t('message.privacy.q5.question')"></div>
					<div class="answer" v-html="$t('message.privacy.q5.answer')"></div>
				</div>
				
				<div class="faq">
					<div class="question medium-large-font" v-html="$t('message.privacy.q6.question')"></div>
					<div class="answer" v-html="$t('message.privacy.q6.answer')"></div>
				</div>
				
				<div class="faq">
					<div class="question medium-large-font" v-html="$t('message.privacy.q7.question')"></div>
					<div class="answer" v-html="$t('message.privacy.q7.answer')"></div>
				</div>
			</div>
		</div>
		
		<div class="content article-page" v-if="page == 'article'">
			<Article :article="article" />
		</div>

		<Footer :page="page"/>
	</div>
</template>

<script>
	import Header from './components/Header.vue'
	import Footer from './components/Footer.vue'
	import Trailer from './components/Trailer.vue'
	import Slider from './components/Slider.vue'
	import Explanation from './components/Explanation.vue'
	import Infos from './components/Infos.vue'
	import Download from './components/Download.vue'
	import Article from './components/Article.vue'
	
	export default {
		name: 'Vairus',
		data: function () {
			return{ 
				page : 'home', 
				article : '' 
			}
		},
		mounted(){
			const urlParams = new URLSearchParams(window.location.search);
			if(urlParams.has('privacy')){
				this.page = 'privacy';
			}
			if(urlParams.has('blackhole')){
				this.page = 'article';
				this.article = 'blackhole';
			}
		},
		methods: {
			goToDownload(){
				var height = this.$refs.download.$el.getBoundingClientRect().top + window.pageYOffset - 100;
				window.scrollTo({top: height, behavior: 'smooth'});
				
			}
		},
		metaInfo() {
			return { 
				title: "Vairus - Catch monsters while browsing the internet!",
				meta: [
					{ name: 'description', content:  'A unique game that you play directly in the browser while browsing the web. Install the extension and start looking for the Vairus hidden on the websites.'},
					{ property: 'og:title', content: "Vairus - Catch monsters while browsing the internet!"},
					{ property: 'og:site_name', content: 'Vairus'},
					{ property: 'og:type', content: 'website' },    
					{ name: 'robots', content: 'index,follow' } 
				]
			}
		}, 
		components: {
			Header,
			Footer,
			Trailer,
			Explanation,
			Infos,
			Slider,
			Download,
			Article,
		}, 
	}
</script>

<style lang="scss">
	@font-face {
		font-family: "Chakra Petch", sans-serif;
		src: local("Chakra Petch"), url(./assets/fonts/ChakraPetch-Regular.ttf) format("truetype");
	}
	
	@font-face {
		font-family: "Chakra Petch", sans-serif;
		src: local("Chakra Petch"), url(./assets/fonts/ChakraPetch-Bold.ttf) format("truetype");
		font-weight: bold;
	}

	@font-face {
		font-family: "The Hustle";
		src: local("The Hustle"), url(./assets/fonts/TheHustle.ttf) format("truetype");
	}
		
	* {
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		-webkit-text-size-adjust: 100%;
	}
		
	html{
		font-family: 'Chakra Petch', sans-serif;
		font-size: 16px;
	}
	body{
		background-color: black;
		color: #B3EDFF;
		margin: 0;
	}
	.hustle{
		font-family: The Hustle;
		font-size: 50px;
	}
	.flex{
		display: flex;
	}
	.flex.space{
		justify-content: space-between;
	}
	.flex.v-center{
		align-items: center;
	}
	.flex.h-right{
		justify-content: flex-end;
	}
	.button{
		display: inline-block;
		position: relative;
		padding: 5px;
		color: black;
		text-transform: uppercase;
		text-align: center;
		font-weight: bold;
		cursor: pointer;
		line-height: 24px;
		height: 34px;
		border-radius: 5px;
		background-color: #B3EDFF;
		
		.inner{
			padding: 0 10px;
		}
		
		&:hover .inner{
			box-shadow: 0px 0px 30px 0px white;
		}
	}
	input, select{
		font-family: 'ChakraPetch', sans-serif;
		font-size: 16px;
	}
	input:not([type=checkbox]),
	textarea,
	select{
		padding: 10px 20px;
		border-radius: 10px;
		min-width: 180px;
		background-color: #0E2D3F;
		color: #B3EDFF;
		border: none;
		font-size: 1em;
		font-family: inherit;
		appearance: none;
		
		&:focus{
			outline: none;
		}
	}

	/* COLUMNS */

	.main-column{
		width: 1280px;
		margin: 0 auto;
	}
	.col-1 { width: 8.33% }
	.col-2 { width: 16.66%; }
	.col-3 { width: 25%; }
	.col-4 { width: 33.33%; }
	.col-5 { width: 41.66%; }
	.col-6 { width: 50%; }
	.col-7 { width: 58.3%; }
	.col-8 { width: 66.66%; }
	.col-9 { width: 75%; }
	.col-10{ width: 83.33%; }
	.col-11{ width: 91.66%; }
	.col-12{ width: 100%; }

	/* PADDINGS */

	.col-padding{
		padding-left: 1rem;
		padding-right: 1rem;
	}
	.col-padding{
		padding-left: 2rem;
		padding-right: 2rem;
	}
	.row-padding{
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
	.double-row-padding{
		padding-top: 2rem;
		padding-bottom: 2rem;
	}	

	/* TYPOGRAPHY */

	.text-center{
		text-align: center;
	}
	.text-right{
		text-align: right;
	}
	.medium-font{
		font-size: 1.2rem;
	}
	.medium-large-font{
		font-size: 1.5rem;
	}
	.large-font{
		font-size: 2rem;
	}

	/* HEADER/FOOTER */

	
	.site-branding{
		position: relative;
		
		&:before {
			content: attr(data-text);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			left: 2px;
			clip: rect(0, 0, 0, 0);
			text-shadow: -2px 0 #ff00c1;
			animation: glitch-anim 5s infinite linear alternate-reverse;
		}
	}


	@keyframes glitch-anim {
		0% {
		clip: rect(47px, 9999px, 21px, 0);
		transform: skew(0.21deg);
		}
		1% {
		clip: rect(23px, 9999px, 77px, 0);
		transform: skew(0.9deg);
		}
		2% {
		clip: rect(56px, 9999px, 84px, 0);
		transform: skew(0.34deg);
		}
		3% {
		clip: rect(88px, 9999px, 14px, 0);
		transform: skew(0.29deg);
		}
		4% {
		clip: rect(31px, 9999px, 51px, 0);
		transform: skew(0.75deg);
		}
		5% {
		clip: rect(41px, 9999px, 48px, 0);
		transform: skew(0.41deg);
		}
		6% {
		clip: rect(24px, 9999px, 55px, 0);
		transform: skew(0.37deg);
		}
		7% {
		clip: rect(14px, 9999px, 13px, 0);
		transform: skew(0.94deg);
		}
		8% {
		clip: rect(0, 0, 0, 0);
		transform: none;
		}
		32% {
		clip: rect(0, 0, 0, 0);
		transform: none;
		}
		33% {
		clip: rect(69px, 9999px, 10px, 0);
		transform: skew(0.74deg);
		}
		34% {
		clip: rect(63px, 9999px, 95px, 0);
		transform: skew(0.45deg);
		}
		35% {
		clip: rect(34px, 9999px, 4px, 0);
		transform: skew(0.28deg);
		}
		36% {
		clip: rect(44px, 9999px, 10px, 0);
		transform: skew(0.22deg);
		}
		37% {
		clip: rect(96px, 9999px, 99px, 0);
		transform: skew(0.47deg);
		}
		38% {
		clip: rect(67px, 9999px, 42px, 0);
		transform: skew(0.1deg);
		}
		39% {
		clip: rect(8px, 9999px, 85px, 0);
		transform: skew(0.4deg);
		}
		40% {
		clip: rect(44px, 9999px, 32px, 0);
		transform: skew(0.64deg);
		}
		41% {
		clip: rect(0, 0, 0, 0);
		transform: none;
		}
		65% {
		clip: rect(0, 0, 0, 0);
		transform: none;
		}
		66% {
		clip: rect(61px, 9999px, 67px, 0);
		transform: skew(0.08deg);
		}
		67% {
		clip: rect(10px, 9999px, 72px, 0);
		transform: skew(0.34deg);
		}
		68% {
		clip: rect(57px, 9999px, 59px, 0);
		transform: skew(0.03deg);
		}
		69% {
		clip: rect(39px, 9999px, 87px, 0);
		transform: skew(0.91deg);
		}
		70% {
		clip: rect(26px, 9999px, 40px, 0);
		transform: skew(0.93deg);
		}
		71% {
		clip: rect(0, 0, 0, 0);
		transform: none;
		}
	}

	/* CONTENT */

	.content{
		min-height: calc(100vh - 180px);
	}
	.card{
		background: radial-gradient(ellipse at center, black 0%, #0E2D3F 100%);
		border-radius: 10px;
	}

	/* VUE TRANSITION */

	.fade-enter-active, .fade-leave-active {
		transition: opacity .35s;
	}
	.fade-enter, .fade-leave-to{
		opacity: 0;
	}
	

	/* VIEWPORTS */

	
	@media screen and (max-width: 1350px) {
		.main-column{
			width: 95%;
		}
	}

	.privacy-page{
		background: radial-gradient(ellipse at center,#000 0,#0e2d3f 100%);
		
		h2{
			padding-top: 150px;
			font-size: 2.5rem;
			text-transform: uppercase;
		}
		
		.faq{
			margin-top: 25px;
			
			.question{
				font-weight: bold;
			}
			.answer{
				width: 66%;
				margin-top: 10px;
			}
			
			&:last-child{
				padding-bottom: 100px;
			}
			
			a{
				color: inherit;
			}
			@media screen and (max-width: 600px) {
				.answer{
					width: 100%;
				}
			}
		}
	}
</style>
