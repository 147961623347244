<template>
	<header>
		<div class="main-column">
			<div class="flex space v-center">
				<h1 class="site-branding hustle col-2" data-text="vairus">
					<a href="/">vairus</a>
				</h1>
			
				<div class="motto col-7">
					{{ $t('message.motto') }}
				</div>

				<div class="col-3 flex h-right">
					<div v-if="page == 'home'" class="button" v-on:click="$emit('download')"><div class="inner">Download</div></div>
				</div>
			</div>
		</div>
		
		<div v-if="page == 'home'">
			<transition name="fade">
				<div class="recover-modal modal" v-if="state == 'recover' || state == 'recover-sent'">
					<div class="backdrop" v-on:click.self="state = ''"></div>

					<div class="inner card">
						<h3 class="hustle text-center">{{ $t("message.reset_password") }}</h3>

						<form autocomplete="off" v-if="state == 'recover'" v-on:submit.prevent="requestReset">
							<div class="recover-message">{{ $t("message.reset_before_message") }}</div>

							<div class="input-wrapper">
								<input name="email" v-model="recover.email" type="email" placeholder="E-mail" autocomplete="off">
							</div>

							<div v-if="recover.error != ''" class="error">{{recover.error}}</div>

							<div class="actions">
								<div class="button" v-on:click="requestReset"><div class="inner">{{ $t("message.reset") }}</div></div>
							</div>	
						</form>

						<div v-else class="text-center">{{ $t("message.reset_message") }}</div>	
					</div>
				</div>
			</transition>

			<transition name="fade">
				<div class="recover-modal modal" v-if="state == 'recover-code' || state == 'recover-success'">
					<div class="backdrop"></div>

					<div class="inner card">
						<h3 class="hustle text-center">{{ $t("message.reset_password") }}</h3>

						<form autocomplete="off" v-if="state == 'recover-code'" v-on:submit.prevent="resetPassword">
							<div class="input-wrapper">
								<input name="password" v-model="recover.password" type="password" placeholder="Password" autocomplete="new-password">
							</div>
							<div class="input-wrapper">
								<input name="password_repeat" v-model="recover.password_repeat" type="password" :placeholder="$t('message.repeat_password')" autocomplete="new-password">
							</div>

							<div v-if="recover.error != ''" class="error">{{recover.error}}</div>

							<div class="actions">
								<div class="button" v-on:click="resetPassword"><div class="inner">{{ $t("message.reset") }}</div></div>
							</div>
						</form>

						<div v-else class="flex space v-center">
							<div>{{ $t("message.reset_message_success") }}</div>
						</div>
					</div>
				</div>
			</transition>
		
		</div>

	</header>
</template>

<script>
	import axios from 'axios';

	export default {
		name: 'Header',
		props : ['page'],
		data: function () {
			return{
				userdata : null,
				state : '',
				server : 'https://playvairus.com/auth',
				recover : {
					email : '',
					code : '',
					password : '',
					password_repeat : '',
					error : ''
				}
			}
		},
		created(){
			const urlParams = new URLSearchParams(window.location.search);
			if(urlParams.has('code')){
				this.state = 'recover-code';
				this.recover.code = urlParams.get('code');
			}
			if(urlParams.has('recover')){
				this.state = 'recover';
			}
		},
		methods: {
			validateEmail(email) {
				//eslint-disable-next-line
				var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				return re.test(email);
			},
			toggleRequestReset(){
				this.state = 'recover';
			},
			requestReset(){
				axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

				var data = new URLSearchParams();
				data.append('email', this.recover.email);
				
				axios.post( this.server+'/request_reset', data)
					.then(function () {
						this.state = 'recover-sent';
					}.bind(this))
					.catch(function (error) {
						this.recover.error = error;
					}.bind(this));
			},
			resetPassword(){
				this.recover.error = '';
				var error = false;

				if(this.recover.password != this.recover.password_repeat){
					this.recover.error = this.$t('message.password_different');
					error = true;
				}
				
				if(this.recover.password.length < 6){
					this.recover.error = this.$t('message.password_short');
					error = true;
				}

				if(!error){
					axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

					var data = new URLSearchParams();
					data.append('password', this.recover.password);
					data.append('code', this.recover.code);
					
					axios.post( this.server+'/reset', data)
						.then(function (response) {
							if(response.data.success){
								this.state = 'recover-success';
								window.history.pushState({},'document.title', window.location.href.split('?')[0])
							}else{
								this.recover.error = this.$t('message.reset_generic_error');
							}
						}.bind(this))
						.catch(function (error) {
							console.log(error);
						}.bind(this));
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	header{
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		z-index: 10;
		padding: 0.5rem 0;
		background-color: black;
		
		.site-branding{
			a{
				color: inherit;
				text-decoration: none;
			}
		}
	
		.profile-link{
			cursor: pointer;
		}

		.modal{
			position: fixed;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			z-index: 10;

			.backdrop{
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				background-color: rgba(0, 0, 0, 0.7);
			}

			.inner{
				position: absolute;
				left: 50%;
				top: 50%;
				max-height: 80vh;
				transform: translate(-50%,-50%);
				padding: 25px;

				h3{
					margin-top: 0;
					margin-bottom: 25px;
				}

				input{
					display: block;
					padding: 5px;	
					width: 300px;
					font-size: 16px;
					margin-bottom: 15px;
				}

				.actions{
					margin-top: 25px;
				}

				.lost-password-link{
					display: block;
					margin-bottom: 15px;
					cursor: pointer;
					text-decoration: underline;;
				}

				.error{
					background-color: red;
					padding: 2px 10px;
					margin-bottom: 15px;
				}

				.recover-message{
					width: 300px;
					margin-bottom: 20px;
				}

				.score{
					width: 300px;
					margin-bottom: 10px;
				}
			}
		}
	}
</style>