<template>
	<div class="article">
		<div class="main-column medium-font">
			<div class="inner" v-if="article == 'blackhole'">
				<h2>Creating a black hole animation</h2>
				
				<p>I was looking to create some cool animations for some of the rarest anomalies that you may encounter visiting websites while playing Vairus and I wanted to try to make a black hole that sucks the website one piece at the time.</p>
			
				<p>The requirements were these:</p>
				
				<ul>
					<li>The animation need to work on most websites.</li>
					<li>It needed to be Vanilla Javascript.</li>
					<li>The animation should be as light as possible.</li>
				</ul>
			
				<p>Here's the result:</p>
				
				<video controls>
					<source src="../assets/blackhole.mp4" type="video/mp4">
				</video>
				
				<br><br>
				
				<h3>Code</h3>
				
				<p>I have made a snippet that you can paste on the console to try the animation on your favourite website:</p>
				
				<iframe class="main" src="https://pastebin.com/embed_iframe/kvM52LEY?theme=dark" style="border:none;width:100%"></iframe>
				
				<br><br>
				
				<h3>Analysis</h3>
				
				<p>Let's analyze the code one part at a time:</p>
				
				<iframe class="part" src="https://pastebin.com/embed_iframe/fnZtNM6D?theme=dark" style="border:none;width:100%"></iframe>
				
				<p>First we need to inject some CSS styles on the page. In the game this is handled directly from the games styles, but for the console version this is needed.</p>
				<p>After that we get all the html nodes of the page (excluding some non relevant ones) and we initialize some useful variables.</p>
				
				<iframe class="part" src="https://pastebin.com/embed_iframe/vZ48mmTP?theme=dark" style="border:none;width:100%"></iframe>
				
				<p>The main function of the script: we select a random html element and we check how many children it has (excluding already absorbed elements). We don't want to absorb an element that has too many children, to prevent the animation to end too soon.</p>
				<p>Here we also check for recursion, to prevent the function getting stuck on non valid elements.</p>
				
				<iframe class="part" src="https://pastebin.com/embed_iframe/RkCFmE9D?theme=dark" style="border:none;width:100%"></iframe>
				
				<p>Then we flag each child of the element, to prevent it from being selected on subsequent iterations.</p>
				<p>After that we clone the element, perfectly copying the position of the element, and we append the clone to the body of the page, while making the original element invisible.</p>
				
				<iframe class="part" src="https://pastebin.com/embed_iframe/D0gekujQ?theme=dark" style="border:none;width:100%"></iframe>
				
				<p>At last we start the animation by setting the CSS position of the clone to the center of the page and we set a timeout to remove the clone after the animation end.</p>
				<p>Then we reduce the set of available elements by removing the selected element and all its chilren and we're ready to call the function again.</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Article',
		props : ['article']
	}
</script>

<style scoped lang="scss">
	.article{
		background: radial-gradient(ellipse at center, #000 0, #0e2d3f 100%);
		padding: 150px 0;
		
		.inner{
			width: 66%;
			
			@media screen and (max-width: 1080px) {
				width: 100%;
			}
			
			p, ul, video, iframe, h2, h3{
				margin-bottom: 25px;
			}
			
			ul{
				margin-left: 40px;
			}
		
			h2{
				font-size: 2.5rem
			}
			h3{
				font-size: 1.8rem;
			}
			iframe{
				height: 400px;
				
				&.part{
					height: 200px;
				}
			}
			video{
				width: 100%;
			}
		}
	}
</style>
