import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueMeta from 'vue-meta'
import App from './App.vue'
import messages from './assets/localization.json'



Vue.use(VueI18n);
Vue.use(VueMeta)
Vue.config.productionTip = false;

const i18n = new VueI18n({
  locale: navigator.language,
  messages,
})

new Vue({
	i18n,
	render: h => h(App),
}).$mount('#app');
