<template>
	<div class="explanation double-row-padding">
		<div class="main-column double-row-padding flex v-center">
			<div class="col-6 text">
				<h3 class="large-font">{{ $t('message.explanation.title') }}</h3>
				
				<div class="medium-large-font">{{ $t('message.explanation.text') }}</div>
			</div>
			<div class="col-6">
				<video autoplay loop>
					<source src="../assets/explanation.mp4" type="video/mp4">
				</video>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Explanation'
	}
</script>

<style scoped lang="scss">
	.explanation{
		.text{
			padding-right: 2rem;
		}
		h3{
			margin-bottom: 1rem;
		}
		video{
			width: 100%;
		}
	}
</style>
