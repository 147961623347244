<template>
	<div class="infos double-row-padding">
		<div class="main-column double-row-padding">
			
			<div class="wrapper flex space medium-large-font">
				<div class="info vairus col-4 col-padding">
					<div class="icon">
						<img src="../assets/egg.png" alt="vairus egg">
					</div>
					
					<div class="text">
						<p v-html="$t('message.info.vairus.line1')"></p>
						<p v-html="$t('message.info.vairus.line2')"></p>
						<p v-html="$t('message.info.vairus.line3')"></p>
					</div>
				</div>
				
				<div class="info network col-4 col-padding">
					<div class="icon">
						<img src="../assets/network.png" alt="network of websites">
					</div>
					
					<div class="text">
						<p v-html="$t('message.info.network.line1')"></p>
						<p v-html="$t('message.info.network.line2')"></p>
						<p v-html="$t('message.info.network.line3')"></p>
					</div>
				</div>
				
				<div class="info matrix col-4 col-padding">
					<div class="icon">
						<img src="../assets/matrix.gif" alt="matrix">
					</div>
					
					<div class="text">
						<p v-html="$t('message.info.matrix.line1')"></p>
						<p v-html="$t('message.info.matrix.line2')"></p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Infos'
	}
</script>

<style scoped lang="scss">
	.infos{
		background: radial-gradient(ellipse at center, black 0%, #41063F 100%);
		color: #FEB3FF;
		
		.wrapper{
			margin: 0 -2rem;
		
			.info{
				.icon img{
					display: block;
					width: 100px;
					margin: 0 auto;
					cursor: pointer;
				}
				.text{
					margin-top: 2rem;
				}
				
				&.matrix .icon img{
					width: 80px;
					padding: 10px 0;
				}
				
				p{
					margin-bottom: 1rem;
				}
			}
		}
	
		@media screen and (max-width: 800px) {
			.wrapper{
				display: block;
				margin: 0;
				
				.info{
					width: 100%;
					margin-bottom: 6rem;
					padding: 0;
					
					&:last-child{
						margin-bottom: 0;
					}
				}
			}
		}
	}
</style>
