<template>
	<div class="trailer flex large-font">
		<div class="main-column flex space">
			<div class="image col-8">
				<img src="../assets/showcase.png" alt="vairus showcase">
			</div>
			
			<div class="text col-4  flex v-center">
				<div>
					<h2 class="large-font" v-html="$t('message.trailer.line1')"></h2>
					<h3 class="large-font" v-html="$t('message.trailer.line2')"></h3>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Trailer'
	}
</script>

<style scoped lang="scss">
	.trailer{
		height: 100vh;
		background-color: black;
		overflow: hidden;
		background: radial-gradient(ellipse at center,#000 0,#0e2d3f 100%);
		
		
		.image{
			height: 100%;
			position: relative;
			
			img{
				position: absolute;
				right: 0;
				top: 0;
				width: 60vw;
				height: 100%;
				object-fit: contain;
			}
		}
		
		.text{
			height: 100%;
			
			h2, h3{
				margin-bottom: 1rem;
				font-weight: normal;
			}
		}
		
	
		@media screen and (max-width: 800px) {
			.main-column{
				display: block;
				
				> div{
					width: 100%;
					height: auto;
					
					img{
						position: relative;
						width: 100%;
						margin-top: 2rem;
					}
					
					&.text{
						width: 66%;
						margin: 0 auto;
						font-size: 1.8rem;
					}
				}
			}
		}
		
		@media screen and (max-width: 660px) {
			img{
				width: 120% !important;
				margin-left: -10%;
				margin-top: 3rem !important;
			}
			.text{
				width: 85% !important;
				font-size: 1.5rem !important;
			}
		}
		@media screen and (max-width: 540px) {
			img{
				width: 140% !important;
				margin-left: -20%;
				margin-top: 4rem !important;
			}
		}
	}
</style>
