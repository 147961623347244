<template>
	<div class="download double-row-padding">
		<div class="main-column double-row-padding">
			<h2 class="title large-font text-center">Download</h2>
			
			<div class="subtitle medium-font text-center" v-html="$t('message.download_subtitle')"></div>
			
			<div class="flex space">
				<div class="col-2">&nbsp;</div>
				<div class="col-4">
					<a href="https://chrome.google.com/webstore/detail/vairus/jpapcilobnmniahfmbgbkfcnlooaomfi" target="_blank"><img src="../assets/chrome.png"></a>
				</div>
				<div class="col-4">
					<a href="https://addons.mozilla.org/en-US/firefox/addon/vairus" target="_blank"><img src="../assets/firefox.png"></a>
				</div>
				<div class="col-2">&nbsp;</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Download',
	}
</script>

<style scoped lang="scss">
	.download{
		background: radial-gradient(ellipse at center, black 0%, #4D2806 100%);
		color: #FFC233;
		padding-bottom: 8rem;
		
		.title{
			padding-bottom: 2rem;
		}
		
		.subtitle{
			padding-bottom: 2rem;
		}
		
		img{
			display: block;
			width: 100px;
			margin: 0 auto;
			cursor: pointer;
		}
	}
	
	
	@media screen and (max-width: 800px) {
		.col-2{
			width: 10%;
		}
	}
</style>
